<template>
  <div class="toggle-switch" :class="{ disabled: disabled }">
    <input
      type="checkbox"
      v-model="isChecked"
      :id="switchId"
      :name="name"
      :disabled="disabled"
    />
    <label :for="switchId">Toggle</label>
  </div>
</template>

<script>
import { uniqueId } from "lodash";

export default {
  components: {},
  mixins: [],
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },
    id: {
      type: [String, Number]
    },
    name: {
      type: String,
      default: "switch"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {};
  },
  computed: {
    isChecked: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    switchId() {
      return this.id || uniqueId("switch_");
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.toggle-switch {
  position: relative;
  display: inline-block;

  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
    display: inline;
    position: absolute;
  }

  @mixin size($width) {
    $w: $width;
    $h: $width/2;

    label {
      width: $w;
      height: $h;
    }
    label:after {
      width: $h - 6px;
      height: $h - 6px;
    }
    label:active:after {
      width: $h + 4px;
    }
  }

  @include size(48px);

  &.xs {
    @include size(24px);
  }
  &.sm {
    @include size(36px);
  }
  &.md {
    @include size(48px);
  }
  &.lg {
    @include size(60px);
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
    transition: 0.3s;
  }

  label:after {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    background: #fff;
    border-radius: 100px;
    transition: 0.3s;
    box-shadow: 0 0 2px 4px #00000015;
  }

  input:checked + label {
    background: $color-main;
  }

  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }

  // Disabled state
  input:disabled {
    + label {
      cursor: not-allowed;
      background: #ccc;
    }
    + label:after {
      background: #eee;
    }
    &:checked + label {
      background: lighten($color-main, 10);
    }
  }
}
</style>
